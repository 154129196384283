import { Component } from '@angular/core';
import { ResetPasswordBaseComponent } from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends ResetPasswordBaseComponent {
}
