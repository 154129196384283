import {Component} from '@angular/core';
import {SideMenuBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent extends SideMenuBaseComponent {
}
