<div *ngIf="!product.addonsCheck" [ngClass]="[featured ? 'list-view': '' , gridClass ? '' : 'list-view' ]"
  [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'product', product | translate: 'slug']"
  [queryParams]="{ variantCode: Mvaraint?.code }" class="widget with-cursor">
  <div class="widget-head" [ngStyle]="{'background-color': esarfa ? 'white' : ''}">
    <div class="widget-img">
     
      <img class="animate-fade spinner-img" [src]="defaultImage" [alt]="product.name" [hidden]="imgLoad" />
      <img class="animate-fade" [src]="imgSrc" [alt]="product.name" (load)="onImageLoad()" [hidden]="!imgLoad" />
          </div>
    <div class="widget-labels">
      <ng-container *ngTemplateOutlet="labels"></ng-container>
      <ng-template #labels>
        <span class="discount"
          *ngIf="product.discounted && (Mvaraint?.prices[0].original_price > Mvaraint?.prices[0].price) && !product.hidePrice "
          placement="right" tooltip="{{language['discount']}}">
          {{ (Mvaraint?.prices[0].original_price - Mvaraint?.prices[0].price) / Mvaraint?.prices[0].original_price |
          percent }}
        </span>
        <span class="bg-light-green new" *ngIf="product.new"> {{language['new']}} </span>
        <span class="bg-red outOfStock" *ngIf="Mvaraint?.tracked && Mvaraint?.stock == 0">
          {{ language['LANGUAGE_CODE'] === 'en_US' ? "Sold Out" : "غير متوفر"}}      

        </span>
        <span class="bg-gray outOfStock" *ngIf="product.limited_quantity">
          {{language['limited_quantity']}}
        </span>
        <span *ngIf="product.bestseller" class="bg-tomato best-sellers" placement="right"
          tooltip="{{language['bestSellers']}}">
          <i class="fa fa-line-chart" aria-hidden="true"></i>
        </span>
        <span *ngIf="product.featured" class="featured-product" placement="right"
          tooltip="{{language['featureProduct']}}">
          <i class="fa fa-star"></i>
        </span>
      </ng-template>
    </div>
    <div class="widget-icons">
      <button *ngIf="wishListFeature$ | async" class="icon-btn wishlist" [ngClass]="{active: fav | async}"
        aria-hidden="true" (click)="$event.stopPropagation();toggleWishList()" tooltip="{{language['wishList']}}">
        <i class="fa fa-heart"></i>
      </button>
      <button *ngIf="compareFeature$ | async" class="icon-btn compare-list" [ngClass]="{active: compare | async}"
        aria-hidden="true" tooltip="{{language['compare']}}" (click)="$event.stopPropagation();toggleCompare()">
        <i class="fa fa-random"></i>
      </button>
    </div>
  </div>
  <div class="widget-content">
    <div class="product-rating" *ngIf="rates?.length && (reviewsFeature$ | async)">
      <rating [(ngModel)]="rate" [max]="maxRate" [readonly]="true"> </rating>
    </div>
    <a [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'product', product | translate: 'slug']"
      [queryParams]="{ variantCode: Mvaraint?.code }" class="widget-title">
      {{product | translate: 'name'}}
    </a>
    <!-- <p class="product-description">
      {{ ((product | translate: 'short_description')?.length > 85) ? ((product | translate: 'short_description') |
      slice:0:85) + '...' : ((product | translate: 'short_description')) }}
    </p> -->
    <div class="widget-price product-price">
      <div class="price">

     
      <div class="price" *ngIf="maxPrice === minPrice; else fromPrice">
        <div class="product-price">
        <div class="price" *ngIf="product.discounted">
          <div class="price__sale">{{ Mvaraint?.prices[0].price / 100 | number}} {{currencyCode}}</div>
          <div class="price__original price__original--discount" [ngClass]="{hidden:!product.discounted}"  *ngIf="Mvaraint?.prices[0].original_price > 0">
            {{Mvaraint?.prices[0].original_price / 100 | number}} {{currencyCode}}
          </div>
        </div>
        <div class="price" *ngIf="!product.discounted">
          <div class="price__sale">{{ Mvaraint?.prices[0].price / 100 | number}} {{currencyCode}}</div>
          <div class="price__original price__original--discount"  [ngClass]="{hidden:!product.discounted}" *ngIf="Mvaraint?.prices[0].original_price > 0">
            {{Mvaraint?.prices[0].original_price / 100 | number}} {{currencyCode}}
          </div>
        </div>
      </div>
      <ng-template #fromPrice>
        <div class="price">
          <div class="price__sale">{{language['from']}} {{ Mvaraint?.prices[0].price / 100 | number}} {{currencyCode}}</div>
        </div>
        <div class="price__original price__original--discount" *ngIf="Mvaraint?.prices[0].original_price > 0">
          {{Mvaraint?.prices[0].original_price / 100 | number}} {{currencyCode}}
        </div>
      </ng-template>
    </div> </div>
    <div class="widget-foot" *ngIf="!product.hidePrice">
      
      <div class="text-center add-to-cart">
        <!-- <button (click)="openQuickView()"
          class="custom-btn addToCartBtn d-none d-md-block m-auto">
          {{ language['addToCart']  }}
        </button> -->

        <button *ngIf="!bookAppointment" (click)="$event.stopPropagation(); openQuickView()"
        class="custom-btn addToCartBtn d-none d-md-block m-auto">
        {{ language['addToCart']  }}
      </button>
      <button *ngIf="bookAppointment" (click)="$event.stopPropagation()"
        class="custom-btn addToCartBtn d-none d-md-block m-auto">
<a routerLink="book-appointment">          {{ language['LANGUAGE_CODE'] === 'en_US' ? "Book Appointment" : "احجز مقابله" }}
</a>        </button>
        
        <button *ngIf="(!product.options || (product.options && product.options.length === 0))" (click)="$event.stopPropagation();openQuickView()" 
         class="custom-btn addToCartBtn d-md-none">
          {{language['addToCart']}}
        </button>
        <button *ngIf="!(!product.options || (product.options && product.options.length === 0))" [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'product', product | translate: 'slug']"
        [queryParams]="{ variantCode: product.variants[0].code }" class="custom-btn addToCartBtn d-md-none">
          {{language['viewDetails']}}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="product.addonsCheck" [ngClass]="[featured ? 'list-view': '' , gridClass ? '' : 'list-view' ]"
  [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'productaddons', product | translate: 'slug']" class="widget with-cursor">
  <div class="widget-head" [ngStyle]="{'background-color': esarfa ? 'white' : ''}">
    <div class="widget-img">
    
      <img class="animate-fade spinner-img" [src]="defaultImage" [alt]="product.name" [hidden]="imgLoad" />
      <img class="animate-fade" [src]="imgSrc" [alt]="product.name" (load)="onImageLoad()" [hidden]="!imgLoad" />
        </div>
    <!--End widget-img-->
    <div class="widget-labels">
      <ng-container *ngTemplateOutlet="labels"></ng-container>
      <ng-template #labels>
        <span class="discount"
          *ngIf="product.discounted && (Mvaraint?.prices[0].original_price > Mvaraint?.prices[0].price)  "
          placement="right" tooltip="{{language['discount']}}">
          {{ (Mvaraint?.prices[0].original_price - Mvaraint?.prices[0].price) / Mvaraint?.prices[0].original_price |
          percent }}
        </span>
        <span class="bg-light-green new" *ngIf="product.new"> {{language['new']}} </span>
        <span class="bg-red outOfStock" *ngIf="Mvaraint?.tracked && Mvaraint?.stock == 0">
          {{ language['LANGUAGE_CODE'] === 'en_US' ? "Sold Out" : "غير متوفر"}}      

        </span>
        <span *ngIf="product.bestseller" class="bg-tomato best-sellers" placement="right"
          tooltip="{{language['bestSellers']}}">
          <i class="fa fa-line-chart" aria-hidden="true"></i>
        </span>
        <span *ngIf="product.featured" class="featured-product" placement="right"
          tooltip="{{language['featureProduct']}}">
          <i class="fa fa-star"></i>
        </span>
      </ng-template>
    </div>
    <div class="widget-icons">
      <button *ngIf="wishListFeature$ | async" class="icon-btn wishlist" [ngClass]="{active: fav | async}"
        aria-hidden="true" (click)="$event.stopPropagation();toggleWishList()" tooltip="{{language['wishList']}}">
        <i class="fa fa-heart"></i>
      </button>
      <button *ngIf="compareFeature$ | async" class="icon-btn compare-list" [ngClass]="{active: compare | async}"
        aria-hidden="true" tooltip="{{language['compare']}}" (click)="$event.stopPropagation();toggleCompare()">
        <i class="fa fa-random"></i>
      </button>
    </div>
  </div>
  <div class="widget-content">
    <div class="product-rating" *ngIf="rates?.length && (reviewsFeature$ | async)">
      <rating [(ngModel)]="rate" [max]="maxRate" [readonly]="true"> </rating>
    </div>
    <a [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'productaddons', product | translate: 'slug']" class="widget-title">
      {{product | translate: 'name'}}
    </a>
    <!-- <p class="product-description">
      {{ ((product | translate: 'short_description')?.length > 85) ? ((product | translate: 'short_description') |
      slice:0:85) + '...' : ((product | translate: 'short_description')) }}
    </p> -->
    <div class="widget-price product-price"> 
      <div class="price" *ngIf="maxPrice === minPrice; else fromPrice">
      <div class="price__sale">{{ Mvaraint?.prices[0].price / 100 | number}} {{currencyCode}}</div>
      <div class="price__original price__original--discount" *ngIf="Mvaraint?.prices[0].original_price > 0">
        {{Mvaraint?.prices[0].original_price / 100 | number}} {{currencyCode}}
      </div>
    </div>
    <ng-template #fromPrice>
      <div class="price">
        <div class="price__sale">{{language['from']}} {{ Mvaraint?.prices[0].price / 100 | number}} {{currencyCode}}</div>
      </div>
      <div class="price__original price__original--discount" *ngIf="Mvaraint?.prices[0].original_price > 0">
        {{Mvaraint?.prices[0].original_price / 100 | number}} {{currencyCode}}
      </div>
    </ng-template>
    </div>
    <div class="widget-foot">
      <div *ngIf="(Mvaraint.prices[0].original_price / 100 > 0 ||  Mvaraint.prices[0].price / 100 > 0)else priceOnSelection">

    </div>
    <ng-template #priceOnSelection>
      <div class="widget-price">
        
        <span class="price priceOnSelection">
          {{language['priceOnSelection'] || 'Price on selection '}} 
        </span>
      </div>
    </ng-template>
      <div *ngIf="product.addonsCheck" class="text-center add-to-cart">
        <button
          [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'productaddons', product | translate: 'slug']" class="custom-btn addToCartBtn m-auto">
          {{language['addToCart']}}
        </button>
      </div>
    </div>
  </div>
</div>
