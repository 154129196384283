<form (ngSubmit)="onSubmit()" [formGroup]="bookingForm" class="modern-form">
    <div class="form-group">
      <label for="name">Name:</label>
      <input type="text" id="name" name="name" formControlName="name">
      <div *ngIf="bookingForm.get('name').invalid && (bookingForm.get('name').dirty || bookingForm.get('name').touched)" class="error-msg">
        <div *ngIf="bookingForm.get('name').hasError('required')">Name is required</div>
      </div>
    </div>
    <div class="form-group">
      <label for="email">Email:</label>
      <input type="email" id="email" name="email" formControlName="email">
      <div *ngIf="bookingForm.get('email').invalid && (bookingForm.get('email').dirty || bookingForm.get('email').touched)" class="error-msg">
        <div *ngIf="bookingForm.get('email').hasError('required')">Email is required</div>
        <div *ngIf="bookingForm.get('email').hasError('email')">Please enter a valid email</div>
      </div>
    </div>
    <div class="form-group">
      <label for="phone">Phone:</label>
      <input type="tel" id="phone" name="phone" formControlName="phone">
      <div *ngIf="bookingForm.get('phone').invalid && (bookingForm.get('phone').dirty || bookingForm.get('phone').touched)" class="error-msg">
        <div *ngIf="bookingForm.get('phone').hasError('required')">Phone is required</div>
      </div>
    </div>
    <div class="form-group">
      <label for="date">Date:</label>
      <input type="date" id="date" name="date" formControlName="date">
      <div *ngIf="bookingForm.get('date').invalid && (bookingForm.get('date').dirty || bookingForm.get('date').touched)" class="error-msg">
        <div *ngIf="bookingForm.get('date').hasError('required')">Date is required</div>
      </div>
    </div>
    <div class="form-group">
      <label for="time">Time:</label>
      <input type="time" id="time" name="time" formControlName="time">
      <div *ngIf="bookingForm.get('time').invalid && (bookingForm.get('time').dirty || bookingForm.get('time').touched)" class="error-msg">
        <div *ngIf="bookingForm.get('time').hasError('required')">Time is required</div>
      </div>
    </div>
    <button type="submit">Book Now</button>
  </form>
  