import {Component} from '@angular/core';
import {ProductBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent extends ProductBaseComponent {
	defaultImage = '../../../../assets/images/Spinner.gif';
	imgLoad = false;
  
	
	id:number=0;

	storeid = this.confService.storeId.subscribe((data) => {
	  this.id = data
	});
	bookAppointment= (this.id == 3408 )? true :false
	esarfa= (this.id == 11540 )? true :false

	
	onImageLoad() {
	  this.imgLoad = true;
	}
  
}
