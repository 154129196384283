import { Component, DoCheck } from '@angular/core';
import { HomeBaseComponent, SliceDownAnimation } from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	animations: [
		SliceDownAnimation
	]
})
export class HomeComponent extends HomeBaseComponent implements DoCheck {

	middleBanner;
	homepage_banner;

	ngDoCheck () {
		this.middleBanner = this.slides.filter(slide => {
			return slide['homePagePosition'] === 'Middle'
		});

		this.homepage_banner = this.taxons.filter((taxon) => {
			const a = [];
			for (const i in taxon.images) {
				if (taxon.images[i]['type'] === 'homepage_banner') {
					a.push(taxon[i]);
					return a;
				}
			}
		});
	}
}
