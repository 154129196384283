import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CompareComponent } from './compare/compare.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { ResetPasswordComponent } from './modules/accounts/reset-password/reset-password.component';
import { NgxBbnZvendoRouting, LanguageBaseComponent, WishListGuard, AccountsGuard, ProfileGuard } from '@bbndev/ngx-bbn-zvendo';
import { VerifyPhoneComponent } from './modules/accounts/verify-phone/verify-phone.component';
import { BookAppointmentComponent } from './book-appointment/book-appointment.component';

const children: Routes = [
	{
		path: '',
		component: HomeComponent
	},
	{
		path: 'book-appointment',
		component: BookAppointmentComponent
	},
	{
		path: 'collection/book-appointment',
		component: BookAppointmentComponent
	},
	{
		path: 'reset-password/:email/:token',
		component: ResetPasswordComponent,
	},
	{
		path: 'contact-us',
		component: ContactUsComponent
	},
	{
		path: 'cart', loadChildren: () => import('./modules/cart/cart.module').then(cart => cart.CartModule)
	},
	{
		path: 'collection', loadChildren: () => import('./modules/collection/collection.module') .then((m) => {
			return m.CollectionModule;
		})
	},
	{
		path: 'compare',
		component: CompareComponent
	},
	{
		path: 'wish-list',
		component: WishListComponent,
		canActivate: [WishListGuard],
	},
	{
		path: 'verify-phone',
		component: VerifyPhoneComponent
	},
	{
		path: 'accounts', loadChildren: () => import('./modules/accounts/accounts.module').then(accounts => accounts.AccountsModule), canActivate: [AccountsGuard]
	},
	{
		path: 'profile', loadChildren: () => import('./modules/profile/profile.module').then(profile => profile.ProfileModule), canActivate: [ProfileGuard]
	},
	...NgxBbnZvendoRouting,
	{
		path: ':fbAdds',
		redirectTo: '',
	},
];

const routes: Routes = [
	{
		path: ':lan',
		children: children

	},
	{
		path: '',
		component: LanguageBaseComponent
	},
	{
		path: '**',
		component: LanguageBaseComponent,
		pathMatch: 'full'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
