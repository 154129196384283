<div class="modal-header">
  <button class="close-btn"  (click)="closeModal()">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <a class="logo" routerLink="/{{language['LANGUAGE_CODE']}}">
    <img *ngIf="logo$ | async as logo" [lazyLoad]="logo" alt="logo">
  </a>
</div>
<div class="modal-body">
  <ng-template [ngIf]='!hasOwnNavigationMenu'>
    <ul class="navbar-nav">
      <li *ngFor="let taxon of taxons" [ngClass]="{'mainActive': taxon.selected}">
        <a *ngIf="!taxon.children" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
          [queryParams]="{taxons: taxon.code}" (click)="modal.hide();removeOverFlow()">
          <span>{{taxon | translate: 'name'}}</span>
        </a>
        <div class="d-flex align-items-center justify-content-between taxonCild"> 
          <a *ngIf="taxon.children"
            routerLink="/{{language['LANGUAGE_CODE']}}/collection" [queryParams]="{taxons: taxon.code}"
            (click)="modal.hide();removeOverFlow()"> <span>{{taxon | translate: 'name'}}</span> 
          </a> 
          <i
            (click)="taxon.selected = !taxon.selected; $event.stopPropagation()"
            *ngIf="taxon.children && !taxon.selected" class='fa fa-plus' aria-hidden='true'>
          </i> 
          <i (click)="taxon.selected = !taxon.selected; $event.stopPropagation()"
            *ngIf="taxon.children && taxon.selected" class='fa fa-minus' aria-hidden='true'>
          </i> 
          </div>
        <ul class="children dropdown-menu" *ngIf="taxon.children && taxon.selected">
          <!-- <li *ngFor="let child of taxon.children" [ngClass]="{'childActive': taxon.selected}" > -->
            <!-- <a *ngIf="!child.children" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
              [queryParams]="{taxons: child.code}" (click)="modal.hide()">
              <span>{{child | translate: 'name'}}</span>
            </a>
            <div class="d-flex align-items-center justify-content-between taxonCild"> 
                <a *ngIf="child.children"
                  routerLink="/{{language['LANGUAGE_CODE']}}/collection" [queryParams]="{taxons: child.code}"
                  (click)="modal.hide()"> <span>{{child | translate: 'name'}}</span> 
                </a> 
                <i
                  (click)="child.selected = !child.selected; $event.stopPropagation()"
                  *ngIf="child.children && !child.selected" class='fa fa-plus' aria-hidden='true'>
                </i> 
                <i (click)="child.selected = !child.selected; $event.stopPropagation()"
                  *ngIf="child.children && child.selected" class='fa fa-minus' aria-hidden='true'>
                </i> 
            </div> -->
            <!-- <ul class="grandChildren dropdown-menu" *ngIf="child.children && child.selected"> -->
              <app-base-header-taxon [taxons]='taxon.children' ></app-base-header-taxon>

            <!-- </ul> -->
          <!-- </li> -->
        </ul>
      </li>
    </ul>
    <ul class="navbar-nav static-pages-nav">
      <li class="nav-item" *ngFor="let staticPage of staticPages">
        <a class="nav-link" [routerLink]="['/', language['LANGUAGE_CODE'], 'page', staticPage.code]"
          (click)="modal.hide();removeOverFlow()">
          {{staticPage | translate:'name'}}
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/', language['LANGUAGE_CODE'], 'contact-us']" (click)="modal.hide()">
          {{language['contact-us']}}
        </a>
      </li>
    </ul>
  </ng-template>

  <ng-template [ngIf]='hasOwnNavigationMenu'>
    <nav id="primary_nav_wrap">
      <app-base-header-item [navItems]="generteUrls(menus)" (closeModal)="modal.hide();removeOverFlow()" [parent]="true">
      </app-base-header-item>
    </nav>
  </ng-template>

  <div class="header-icons">
    <div class="header-icons-item logins">
      <a *ngIf="logged$ | async; else login;" [routerLink]="['/', language['LANGUAGE_CODE'], 'profile', 'dashboard']"
        (click)="modal.hide();removeOverFlow()">
        <i class="fa fa-user" aria-hidden="true"></i>
        <span>{{userName | async}}</span>
      </a>
      <a *ngIf="logged$ | async" (click)="logout();modal.hide();removeOverFlow()" class="logOut">
        <i class="fa fa-sign-out" aria-hidden="true"></i>
        <span>{{language['logout']}}</span>
      </a>
      <ng-template #login>
        <a [routerLink]="['/', language['LANGUAGE_CODE'], 'accounts', 'login']" (click)="modal.hide()">
          <i class="fa fa-user" aria-hidden="true"></i>
          <span>{{language['login']}}</span>
        </a>
      </ng-template>
    </div>
    <!--End Header-icons-items-->
    <div class="header-icons-item" *ngIf="wishListFeature$ | async">
      <a [routerLink]="['/', language['LANGUAGE_CODE'], 'wish-list']" (click)="modal.hide();removeOverFlow()">
        <i class="fa fa-heart" aria-hidden="true"></i>
        <span>{{language['wishList']}}</span>
        <span class="label">{{wishListCounter | async}}</span>
      </a>
    </div>
    <!--End Header-icons-items-->

    <div class="header-icons-item" *ngIf="compareFeature$ | async">
      <a routerLink="/{{language['LANGUAGE_CODE']}}/compare" (click)="modal.hide();removeOverFlow()">
        <i class="fa fa-random" aria-hidden="true"></i>
        <span>{{language['compare']}}</span>
        <span class="label">{{compareCounter | async}}</span>
      </a>
    </div>
    <!--End header-icons-item-->

    <div class="header-icons-item">
      <a href="#" [routerLink]="['/', language['LANGUAGE_CODE'], 'cart']" id="cart" (click)="modal.hide();removeOverFlow()">
        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
        <span>{{ language['cart'] }}</span>
        <span class="label">{{ cartCounter | async }}</span>
      </a>
    </div>
    <!--End Header-icons-items-->
    <div class="header-icons-item" *ngIf="availableLanguages?.length > 1">
      <div id="lang" class="dropdown" (click)="openLanguageList = !openLanguageList">
        <a class="nav-item">
          <i class="fa fa-language"></i>
          <span>{{language['language']}}</span>
        </a>
        <ul id="languages" class="dropdown-menu" *ngIf="openLanguageList">
          <li *ngFor="let availableLanguage of availableLanguages" [ngStyle]="{direction: availableLanguage.dir}" (click)="refresh()">
            <a [ngClass]="{activated: availableLanguage.code === language['LANGUAGE_CODE']}" [queryParams]="queryParams"
              [routerLink]="redirectUrls[availableLanguage.code]" (click)="modal.hide();removeOverFlow()">
              {{availableLanguage.name}}
            </a>
          </li>
        </ul>
      </div>
      <!--End Dropdown-language-->
    </div>

    <div class="header-icons-item">
      <app-currencies-side-menu [currencies]="(conf$ | async)?.currencies" id="app-currencies-side-menu"></app-currencies-side-menu>
    </div>
    <div class="header-icons-item">
      <app-branch-selector [mutlipleBranchesFeature]='multipleBranchsFeature'></app-branch-selector>
    </div>

 <!-- currencies ---------------------------------------------> 
    <!-- <div class="header-icons-item" *ngIf="currencies?.length > 1">
      <div id="lang" class="dropdown">
      
          <select [(ngModel)]="current" (change)="changeCurrency($event.target.value)" id="languages" class="dropdown-menu">
              <option  [value]="availableCurencies?.code" *ngFor="let availableCurencies of currencies">
             {{availableCurencies?.name}}
               </option>
          </select>
      </div>
  </div> -->
  <!--End Header-icons-->

  <!--End Header-search-->
</div>