<div class="home">
  <section class="home-slider bg-gray">

    <owl-carousel #slider [carouselClasses]="['owl-theme']" [items]="slides"
      [options]="changeCarouselOptions({margin: 0})" [hidden]='!sliderLoaded'>
      <a (click)="goToSliderLink(slide)" *ngFor="let slide of slides" class="item"
        [ngStyle]="{'cursor': slide.externalLink? 'pointer' : 'default'}"
        title="{{slide.externalLink? 'go to ' + slide.externalLink : ''}}">
        <img [src]="slide.picture" alt="slide" (load)="slideLoaded(slide)" [hidden]="!slide.loaded"
          [errorImage]="defaultImage" class="animate-fade">
      </a>
    </owl-carousel>
    <div class='spinner' *ngIf="!sliderLoaded && slides.length">
      <owl-carousel>
        <app-spinner></app-spinner>
      </owl-carousel>
    </div>

  </section>
  <!--End Home-slider-->

  <!-- Banner Area Start -->
  <section class="section-settings">
    <div class="banner-area first-banner pb-5">
      <div class="banner-container">
        <div class="row">
          <div class="col-lg-4" *ngFor="let slide of middleBanner | slice: 0: 3">
            <a class="banner-image" (click)="goToSliderLink(slide)"
              [ngStyle]="{'cursor': slide.externalLink? 'pointer' : 'default'}">
              <img [errorImage]="defaultImage" [lazyLoad]="slide.picture">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Banner Area End -->

  <section *ngIf="featuredProducts?.length>0" class="section-settings features-products custom-owl-nav">
    <div class="container">
      <div class="section-head">
        <h3 class="section-title h3--custom">
          {{language['featuredProducts']}}
        </h3>
        <a [queryParams]="{featured: true, page: 1}" [routerLink]="['/', language['LANGUAGE_CODE'], 'collection']"
          class="more-btn link--body">{{language['viewAll']}}</a>
      </div>
      <!--End Section-head-->
      <div class="section-content">
        <owl-carousel #owlFeaturedProducts [carouselClasses]="['owl-theme', 'owl-flex']"
          [options]="changeCarouselOptions(featureOption)">
          <!-- <ng-content select=".featured-products"></ng-content> -->
          <div *ngFor="let featuredProduct of featuredProducts;let first = first;let last = last;"
            [ngClass]="{first: first, last: last}" class="featured-products item">
            <app-product [featured]="true" [product]="featuredProduct"></app-product>
          </div>
        </owl-carousel>
      </div>
      <!--End Section-content-->
    </div>
    <!--End container-->
  </section>
  <!--End features-products-->

  <!-- Banner Two Area Start -->
  <div class="banner-area pb-5" *ngIf="homepage_banner?.length > 0">
    <div class="container">
      <div class="row custom-margin--10">
        <ng-container *ngFor="let taxon of homepage_banner; let i = index">
          <div class="col-lg-6 col-md-6 custom-padding-10" *ngIf="i === 0">
            <div class="banner-image-wrap">
              <a [queryParams]="{taxons: taxon.code}" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
                class="banner-image">
                <ng-container *ngFor='let image of taxon.images'>
                  <img [errorImage]="defaultImage" [lazyLoad]="imageUrl + image.path"
                    *ngIf='image.type === "homepage_banner"'>
                </ng-container>
              </a>
              <div class="banner-hover-text">
                <h5>
                  <a [queryParams]="{taxons: taxon.code}" routerLink="/{{language['LANGUAGE_CODE']}}/collection">{{taxon
                    |
                    translate:'name'}}</a>
                </h5>
                <span>{{language['shop']}}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="col-lg-6 col-md-6 custom-padding-10">
          <div class="row custom-margin--10">
            <ng-container *ngFor="let taxon of homepage_banner; let i = index">
              <div class="col-lg-6 col-md-6 custom-padding-10 mb-20" *ngIf="i > 0 && i < 5">
                <div class="banner-image-wrap">
                  <a [queryParams]="{taxons: taxon.code}" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
                    class="banner-image">
                    <ng-container *ngFor='let image of taxon.images'>
                      <img [errorImage]="defaultImage" [lazyLoad]="imageUrl + image.path"
                        *ngIf='image.type === "homepage_banner"'>
                    </ng-container>
                  </a>
                  <div class="banner-hover-text">
                    <h5><a [queryParams]="{taxons: taxon.code}"
                        routerLink="/{{language['LANGUAGE_CODE']}}/collection">{{taxon | translate:'name'}}</a></h5>
                    <span>{{language['shop']}}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Banner Two Area End -->


  <section *ngIf="bestsellers?.length>0" class="section-settings best-sellers custom-owl-nav">
    <div class="container">
      <div class="section-head">
        <h3 class="section-title h3--custom">
          {{language['bestSellers']}}
        </h3>
        <a [queryParams]="{bestseller: true, page: 1}" [routerLink]="['/', language['LANGUAGE_CODE'], 'collection']"
          class="more-btn link--body">{{language['viewAll']}}</a>
      </div>
      <!--End Section-head-->
      <div class="section-content">
        <owl-carousel #owlBestsellersProducts [carouselClasses]="['owl-theme', 'owl-flex']" [options]="changeCarouselOptions(bestsellersOption,{
            responsive : {
              nav: true,
            '0': {
              stagePadding: 7,
              items: 1,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '480': {
              stagePadding: 7,
              items: 2,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '768': {
              stagePadding: 7,
              items: 3,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '992': {
              nav: true,
              items: 4,
              autoplay:true,
              transitionStyle : 'fade'
            }
          }
        })">
          <!-- <ng-content select=".best-sellers"></ng-content> -->
          <div *ngFor="let bestseller of bestsellers;let first = first;let last = last;"
            [ngClass]="{first: first, last: last}" class="item best-sellers">
            <app-product [product]="bestseller"></app-product>
          </div>
        </owl-carousel>
      </div>
      <!--End Section-content-->
    </div>
    <!--End Container-->
  </section>
  <!--End Section-->
  <section class="section-banner">
    <div class="container text-center">
      <app-spinner *ngIf="!bannerLoaded"></app-spinner>
      <img [lazyLoad]="homeBanner$ | async" (load)="bannerLoaded = true" class="img-fluid" [errorImage]="defaultImage">
    </div>
  </section>
  <!--End section-banner-->
  <section *ngIf="discounted?.length>0" class="section-settings custom-owl-nav discount">
    <div class="container">
      <div class="section-head">
        <h3 class="section-title h3--custom">
          {{language['discount']}}
        </h3>
        <a [queryParams]="{discounted: true, page: 1}" [routerLink]="['/', language['LANGUAGE_CODE'], 'collection']"
          class="more-btn link--body">{{language['viewAll']}}</a>
      </div>
      <!--End Section-head-->
      <div class="section-content">
        <owl-carousel #owlDiscountedItem [carouselClasses]="['owl-theme', 'owl-flex']" [options]="changeCarouselOptions(bestsellersOption,{
            responsive : {
              nav: true,
            '0': {
              stagePadding: 7,
              items: 1,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '480': {
              stagePadding: 7,
              items: 2,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '768': {
              stagePadding: 7,
              items: 3,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '992': {
              nav: true,
              items: 4,
              autoplay:true,
              transitionStyle : 'fade'
            }
          }
        })">
          <!-- <ng-content select=".discounted"></ng-content> -->
          <div *ngFor="let discountedItem of discounted;let first = first;let last = last;"
            [ngClass]="{first: first, last: last}" class="item discounted">
            <app-product [product]="discountedItem"></app-product>
          </div>
        </owl-carousel>
      </div>
      <!--End Section-content-->
    </div>
    <!--End Container-->
  </section>
  <!--End section-->

  <section *ngIf="newArrivals?.length>0" class="section-settings custom-owl-nav newArrival">
    <div class="container">
      <div class="section-head">
        <h3 class="section-title h3--custom">
          {{language['newArrivals']}}
        </h3>
        <a [queryParams]="{new: true, page: 1}" [routerLink]="['/', language['LANGUAGE_CODE'], 'collection']"
          class="more-btn link--body">{{language['viewAll']}}</a>
      </div>
      <!--End Section-head-->
      <div class="section-content">
        <owl-carousel #owlNewItem [carouselClasses]="['owl-theme', 'owl-flex']" [options]="changeCarouselOptions(bestsellersOption,{
            responsive : {
              nav: true,
            '0': {
              stagePadding: 7,
              items: 1,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '480': {
              stagePadding: 7,
              items: 2,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '768': {
              stagePadding: 7,
              items: 3,
              autoplay:true,
              transitionStyle : 'fade'
            },
            '992': {
              nav: true,
              items: 4,
              autoplay:true,
              transitionStyle : 'fade'
            }
          }
        })">
          <!-- <ng-content select=".new-arrivals"></ng-content> -->
          <div *ngFor="let item of newArrivals;let first = first;let last = last;"
            [ngClass]="{first: first, last: last}" class="new-arrivals item">
            <app-product [product]="item"></app-product>
          </div>
        </owl-carousel>
      </div>
      <!--End Section-content-->
    </div>
    <!--End Container-->
  </section>
  <!--End section-->
</div>
<!--End Home-->