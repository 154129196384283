import {AfterViewInit, Component} from '@angular/core';
import {BreadcrumbBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends BreadcrumbBaseComponent implements AfterViewInit {
	id:number=0;

	storeid = this.confService.storeId.subscribe((data) => {
	  this.id = data
	});
	karteen=this.id ==12839?true:false
	ngAfterViewInit(): void {
		
		setTimeout(() => {
			console.log(this.breadcrumbs);
			
		}, 3000);
	}
}
