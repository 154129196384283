<div class="page-head" *ngIf="breadcrumbs.length > 0">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs; let l = last">
          <a *ngIf="breadcrumb.name === 'cart'" [routerLink]="breadcrumb.url.path"
            [queryParams]="breadcrumb.url.params">{{language['Shopping_Cart']}}</a>
            <a *ngIf="breadcrumb.name !== 'cart' && breadcrumb.name !=='contact us'" [routerLink]="breadcrumb.url.path"
              [queryParams]="breadcrumb.url.params">{{ breadcrumb.name }}</a>
            <a *ngIf="breadcrumb.name !== 'cart' && breadcrumb.name =='contact us'" [routerLink]="breadcrumb.url.path"
              [queryParams]="breadcrumb.url.params"> {{ language['LANGUAGE_CODE'] === 'en_US' ? "Contact Us" : "تواصل معنا" }}</a>
        </li>
        <!-- <li class="breadcrumb-item"><a href="#">Library</a></li>
        <li class="breadcrumb-item active" aria-current="page">Data</li> -->
      </ol>
    </nav>
  </div><!--End Container-->
</div><!--End Page-head-->
