<div class="container-fluid bg-light pt-5 pt-md-0" id="subDomainContainer">
  <div
    class="container d-flex align-items-center justify-content-center h-100 w-100"
  >
    <div class="row w-100">
      <div class="col-12 text-center mb-5">
        <h1 class="display-4">Choose your school</h1>
      </div>
      <div class="col-6 col-md-3 mb-5" *ngFor="let domain of subDomains">
        <a
          [href]="domain.url"
          class="card h-100"
          [ngClass]="{ disabled: domain.url === '#' }"
        >
          <div
            class="h-100 d-flex align-items-center justify-content-center"
          >
            <img [src]="domain.img" alt="" />
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
