import { AfterViewInit, Component } from '@angular/core';
import { AppBaseComponent } from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent extends AppBaseComponent implements AfterViewInit {

	// envUrl = environment.apiUrl;
	// url = localStorage.getItem('api_url');
	//   envUrl = environment.apiUrl;
	// url = window.location.href;
	// urlId = this.confService.storeId.value;

	// isLoaded = false;
	url = 'https://uniform-locker.com/store/web/app.php/api/';
	isUniformLocker = false;
	id:number=0;

	storeid = this.confService.storeId.subscribe((data) => {
	  this.id = data
	});

	Ramco= (this.id == 13246 )? true :false


	ngAfterViewInit () {
		this.confService.getConf().subscribe((res) => {

			if (res.api_url == this.url && res.id == 1117) {
				this.isUniformLocker = true;
			}
		})
		if(this.Ramco){

			document.querySelector('.ng-placeholder').textContent = 'Pharmacy';
		}

	}
}
