<form #searchForm class="search-form mt-3 mt-md-0 mb-3 mb-md-0">
  <input #search
         name="search-term"
         autocomplete="off"
         placeholder="{{language['search']}}"
         (keyup)="changed($event.target.value)"
         (keyup.enter)="serchResult($event.target.value)"
         (focusout)="focusOutFunction()"
         class="form-control"
         aria-label="Search">
  <select class="category-select" [(ngModel)]="selectedTaxon" [ngModelOptions]="{standalone: true}">
    <option value="undefined" selected>{{language['allCategories']}}</option>
    <option *ngFor="let taxon of taxons" value='{{taxon.code}}'>{{taxon | translate: 'name'}}</option>
  </select>
  <i class="fa fa-angle-down"></i>
  <button class="icon-btn"  (click)="autoComplete = false; serchResult(search.value)">
    <i class="fa fa-search" aria-hidden="true"></i>
  </button>

  <ul id="completionList" *ngIf="autoComplete">
    <li
      (click)="autoComplete = false"
      [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'product', product['slug']]"
      *ngFor="let product of completeToThose | slice:0:[3]">
      {{product['name']}}
    </li>
    <li *ngIf="showNoAvaliableStatus" (click)="autoComplete = false">
      <span> {{language['noProductsAvaliable']}} </span>
    </li>
    <li *ngIf="showAllProducts">
      <a
        (click)="autoComplete = false; serchResult(search.value)"
      >
        {{language['showAllProducts']}}
      </a>
    </li>
  </ul>
</form>
