import {Component, HostListener} from '@angular/core';
import {HeaderBaseComponent} from '@bbndev/ngx-bbn-zvendo';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends HeaderBaseComponent {
	isFixed = false;
	id:number=0;
	storeid = this.confService.storeId.subscribe((data) => {
	  this.id = data
	});
    showLanguageDropdown=this.id==9963? true:false

	@HostListener('window:scroll', [])
	onWindowScroll() {
	  // Check the scroll position and update isFixed accordingly
	  this.isFixed = window.pageYOffset >= 50;
	}

}