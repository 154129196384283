<!--Start Header-->
<header class="header" offset="300">
	<!-- Top header -->
	<div class="top-header">
		<div class="container">
			<div class="d-flex justify-content-between">
				<!-- Language -->
				<div class="header-options">

					<div class="dropdown language-dropdown" *ngIf="availableLanguages?.length > 1&&currentLocale && !showLanguageDropdown">
						<a class="nav-item">
							<i class="fa fa-language"></i>
							<a [href]="currentLocale.code == 'en_US' ? 'ar_EG' : 'en_US'" [ngStyle]="{direction: currentLocale.dir}">{{currentLocale.code == 'en_US' ? 'Arabic' : 'English'}}</a>
						</a>
						<!-- <ul class="dropdown-menu">
							<li *ngFor="let availableLanguage of availableLanguages" (click)="refresh()"
								[ngStyle]="{direction: availableLanguage.dir}">
								<a [ngClass]="{activated: availableLanguage.code == language['LANGUAGE_CODE']}"
									[queryParams]="queryParams" [routerLink]="redirectUrls[availableLanguage.code]">
									{{availableLanguage.name}}
								</a>
							</li>
						</ul> -->

					</div>
					<div class="dropdown language-dropdown" *ngIf="showLanguageDropdown">
						<div class="custom-dropdown">
							<button class="language-btn" type="button" id="customDropdownButton" aria-haspopup="true" aria-expanded="false">
							  <i class="fa fa-language" aria-hidden="true"></i>
							</button>
							<div class="custom-dropdown-content" aria-labelledby="customDropdownButton">
								<li *ngFor="let availableLanguage of availableLanguages" (click)="refresh()">
									<a class="lang-a" [queryParams]="queryParams"
									  [routerLink]="redirectUrls[availableLanguage.code]">{{availableLanguage.name}}</a>
								  </li>
							</div>
						  </div>
						  
				  </div>
						  
					<app-currencies-base [currencies]="(conf$ | async)?.currencies"></app-currencies-base>
					<app-branch-selector [mutlipleBranchesFeature]='multipleBranchsFeature'></app-branch-selector>
					<span *ngIf="(storeIsMultiLocations$ | async)" class="branchLocation">
						<i *ngIf="branch && branch.area && branch.area.name"> {{branch.area.name}} </i>
						<svg (click)="openBranchModal()" id="pop-up-window" xmlns="http://www.w3.org/2000/svg" width="18"
							height="18" viewBox="0 0 18 18">
							<path id="Path_9" data-name="Path 9"
								d="M17.385,10.8a.616.616,0,0,0-.615.615v4.094a1.26,1.26,0,0,1-1.259,1.259H2.489a1.288,1.288,0,0,1-.341-.047l6.395-6.395v3.261a.615.615,0,1,0,1.23,0V8.812a.039.039,0,0,0,0-.014.049.049,0,0,0,0-.016c0-.005,0-.012,0-.018s0-.007,0-.012,0-.012,0-.019,0-.007,0-.011a.075.075,0,0,1,0-.019s0-.007,0-.011l-.005-.019s0-.007,0-.011,0-.011-.005-.018a.112.112,0,0,1,0-.012l-.005-.016L9.728,8.6a.086.086,0,0,0-.005-.012.1.1,0,0,0-.007-.014L9.71,8.566c0-.005-.005-.011-.009-.016A.038.038,0,0,0,9.7,8.541a.154.154,0,0,1-.011-.018L9.68,8.515A.155.155,0,0,1,9.67,8.5l-.005-.009-.011-.016s-.005-.005-.007-.009L9.636,8.45a.077.077,0,0,1-.011-.012.047.047,0,0,1-.009-.011A.27.27,0,0,1,9.6,8.4a.27.27,0,0,0-.023-.021.047.047,0,0,0-.011-.009L9.55,8.364l-.014-.011a.038.038,0,0,1-.009-.007l-.016-.011L9.5,8.33,9.487,8.32l-.009-.005a.055.055,0,0,0-.018-.009L9.452,8.3l-.016-.009-.011-.005-.014-.007L9.4,8.274l-.014-.005-.016-.005a.033.033,0,0,0-.012,0l-.018-.005-.011,0-.019-.005-.011,0a.075.075,0,0,0-.019,0l-.011,0-.019,0-.012,0-.018,0a.049.049,0,0,1-.016,0,.039.039,0,0,1-.014,0H4.412a.615.615,0,0,0,0,1.23H7.673L1.278,15.852a1.288,1.288,0,0,1-.047-.341V2.489A1.26,1.26,0,0,1,2.489,1.23H6.583a.615.615,0,0,0,0-1.23H2.489A2.491,2.491,0,0,0,0,2.489V15.511A2.491,2.491,0,0,0,2.489,18H15.511A2.491,2.491,0,0,0,18,15.511V11.417A.616.616,0,0,0,17.385,10.8Z"
								transform="translate(0 0)" fill="#fff" />
							<path id="Union_1" data-name="Union 1"
								d="M-2644.815,16.031A2.184,2.184,0,0,1-2647,13.847V11.185A2.186,2.186,0,0,1-2644.815,9h2.661a2.186,2.186,0,0,1,2.184,2.185v2.661a2.183,2.183,0,0,1-2.184,2.184Z"
								transform="translate(2657.969 -9)" fill="#fff" />
						</svg>

					</span>
				</div>


				<!-- Top headers links -->
				<nav>
					<ul class="top-header-links">
						<li class="top-header-item" *ngIf="wishListFeature$ | async">
							<a routerLink="/{{language['LANGUAGE_CODE']}}/wish-list">{{language['wishList']}}</a>
							<span>{{wishListCounter$ | async}}</span>
						</li>
						<li class="top-header-item" *ngIf="compareFeature$ | async">
							<a routerLink="/{{language['LANGUAGE_CODE']}}/compare">{{language['compare']}}</a>
							<span>{{compareCounter$ | async}}</span>
						</li>
						<ng-template #login>
							<li class="top-header-item" id="login">
								<a [routerLink]="['/', language['LANGUAGE_CODE'], 'accounts', 'login']">
									{{language['login']}}</a>
								<span>
									<i class="fa fa-user" aria-hidden="true"></i>
								</span>
							</li>
						</ng-template>
						<li class="top-header-item" *ngIf="logged$ | async; else login;">
							<a [routerLink]="['/', language['LANGUAGE_CODE'], 'profile', 'dashboard']">
								{{userName | async}}</a>
							<span><i class="fa fa-user" aria-hidden="true"></i></span>
						</li>
						<li class="top-header-item" *ngIf="logged$ | async">
							<a [routerLink]="['/accounts/login']" routerLinkActive="router-link-active" (click)="logout()">
								{{language['logout']}}
							</a>
							<span><i class="fa fa-sign-out" aria-hidden="true"></i></span>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</div>
	<div class="main-header">
		<div class="container">
			<div class="row no-gutters align-items-center justify-content-center">
				<div class="d-block d-md-none toggle-side-menu col-2">
					<button class="navbar-toggler" type="button" (click)="openModal(menu)" aria-controls="navbarNavAltMarkup"
						aria-expanded="false" aria-label="Toggle navigation">
						<span class="fa fa-bars"></span>
					</button>
				</div>
				<!-- Store logo -->
				<div class="col-lg-3 col-md-4 col-7 text-center">
					<a routerLink="/{{language['LANGUAGE_CODE']}}" class="navbar-brand logo">
						<img *ngIf="logo$ | async as logo" [src]="logo" alt="logo">
					</a>
				</div>
				<!-- Cart counter and redirect to cart page -->
				<div class="col-lg-2 col-md-1 col-2 order-md-last cart-dropdown">
					<button class="cart-btn cart-wrapper" type="button" routerLink="/{{language['LANGUAGE_CODE']}}/cart">
						<span>
							<span>{{ language['cart'] }}</span> {{ cartCounter$ | async }}
						</span>
						<i class="fa fa-shopping-cart"></i>
						<app-base-mini-cart></app-base-mini-cart>
					</button>
				</div>
				<!-- Search products -->
				<div class="col-lg-7 col-md-7 mb-4 mb-md-0">
					<app-search></app-search>
				</div>
				
				<!-- Btn to Toggle sidebar in mobile screens -->
			</div>
			<!-- Sidebar in mobile screens -->
			<ng-template [ngIf]='!hasOwnNavigationMenu'>
				<nav class="navbar navbar-expand-md navbar-light">
				  <div class="collapse navbar-collapse">
					<ul class="navbar-nav categories-list">
					  <li class="nav-item dropdown">
						<a class="nav-link" routerLink="/{{language['LANGUAGE_CODE']}}/collection">
						  <i class="fa fa-bars text-yellow"></i>
						  <span>{{language['allCategories']}}</span>
						</a>
		  
						<ul class="dropdown-menu">
						  <li class="dropdown" *ngFor="let taxon of taxons" [value]="taxon.code" (click)="taxon.selected = !taxon.selected; $event.stopPropagation()"
							[ngClass]="{'mainActive': taxon.selected}" (mouseenter)="taxon.selected = true" (mouseleave)="taxon.selected = false">
							<a class="dropdown-item" *ngIf="!taxon.children" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
							  [queryParams]="{taxons: taxon.code}">
												 {{taxon | translate: 'name'}}
		  
							</a>
		  
							<a class="dropdown-item" *ngIf="taxon.children" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
							  [queryParams]="{taxons: taxon.code}">
							  {{taxon | translate: 'name'}}
							  <i class="fa fa-plus"></i>
							</a>
							<ul class="dropdown-menu children" *ngIf="taxon.children && taxon.selected">
							  <li class="dropdown" *ngFor="let child of taxon.children" (click)="child.selected = !child.selected; $event.stopPropagation()"
								[ngClass]="{'childActive': taxon.selected}" (mouseenter)="child.selected = true" (mouseleave)="child.selected = false">
								<a class="dropdown-item" *ngIf="!child.children" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
								  [queryParams]="{taxons: child.code}">
								  {{child | translate: 'name'}}
								</a>
		  
								<a class="dropdown-item" *ngIf="child.children">
								  {{ child | translate: 'name' }}
								  <i class="fa fa-plus"></i>
								</a>
								<ul class="dropdown-menu children" *ngIf="child.children && child.selected">
								  <li *ngFor="let child of child.children">
									<a class="dropdown-item" routerLink="/{{language['LANGUAGE_CODE']}}/collection"
									  [queryParams]="{taxons: child.code}">{{child|translate:'name'}}</a>
								  </li>
								</ul>
							  </li>
							</ul>
						  </li>
						</ul>
					  </li>
					</ul>
				  </div>
				</nav>
			  </ng-template>

			<ng-template [ngIf]='hasOwnNavigationMenu && isLargeScreen'>
				<nav id="primary_nav_wrap">
					<app-base-header-item [navItems]="generteUrls(menus)" (closeModal)="modal.hide()" [parent]="true"
						id="dynamicMenu"></app-base-header-item>
				</nav>
			</ng-template>
		</div>
	</div>
</header>
<!-- Sidebar component -->
<ng-template #menu>
	<app-side-menu [modal]="modalRef"></app-side-menu>
</ng-template>
