import { Component } from '@angular/core';

@Component({
	selector: 'app-subdomain-selector',
	templateUrl: './subdomain-selector.component.html',
	styleUrls: ['./subdomain-selector.component.scss'],
})
export class SubdomainSelectorComponent {
  subDomains = [
  	{ url: 'https://wils.zvendostore.com/en_US', img: 'https://i.ibb.co/MSyZcr8/Whats-App-Image-2024-07-16-at-11-58-28-AM-3.jpg' },
  	{ url: 'https://bse.uniform-locker.com/en_US', img: 'https://i.ibb.co/MfTs41n/BSE.jpg' },
  	{ url: 'https://nac.uniform-locker.com/en_US', img: 'https://i.ibb.co/LzH4gwr/narmer.jpg' },
  	{ url: 'https://sis.zvendostore.com/en_US', img: 'https://i.ibb.co/B6dTDHF/SIS.jpg' },
  	{ url: 'https://sils.zvendostore.com', img: 'https://i.ibb.co/rM9NbrJ/Whats-App-Image-2024-07-16-at-11-58-28-AM-2.jpg' },
  	{ url: 'http://isee.uniform-locker.com', img: 'https://i.ibb.co/p0q79xv/ISEE.png' },
  	{ url: 'http://ase.zvendostore.com', img: 'https://i.ibb.co/L6Q3yv8/ASE.jpg' },
  	{ url: 'https://kayan-college.zvendostore.com/en_US', img: 'https://i.ibb.co/2hY6Kh4/Whats-App-Image-2024-08-06-at-3-21-35-PM-1.jpg' },
  ];
  constructor () {}

}



