<section class="wishList-page">
    <div class="wishListBody" *ngIf="wishlist">
      <!-- Title -->
      <div class="wishList-header">
        <div class="container">
          <div class="d-flex justify-content-between">
            <div class="">
              <p class="header-title"> {{language['wishList']}}</p>
            </div><!--End Col-->
            <div class="">
              <div class="header-buttons" *ngIf="wishlist.length > 0;">
                <button class="custom-btn" (click)="clearWishList()">{{language['remove']}}</button>
              </div>
            </div><!--End Col-->
          </div><!--End Row-->
        </div><!--End Container-->
      </div><!--End wishlist-header-->
      <div class="wishlist-content" *ngIf="wishlist.length > 0 ; else showmessage">
        <div class="container">
          <div class="product-wishlist" *ngFor="let fav of wishlist">
            <div class="product-head">
              <div class="product-img" appImage="" [product]="fav">
                <!--<img [src]="imageUrl + fav.images[0]?.path" class="img-fluid ">-->
              </div><!--End product-img-->
            </div><!--End Product-head-->
            <div class="product-content">
              <div class="product-name">
                <a [routerLink]="['/',language['LANGUAGE_CODE'],'collection', 'product', fav | translate: 'slug']">{{ fav.name }}</a>
              </div><!--End product-name-->
              <div class="product-price" *ngIf="wishlist.hidePrice">
                    <span class="current-price">
                      {{ fav.variants[0].prices[0].price/100 }} {{currencyCode}}

                    </span>
                <span class="original-price" *ngIf="fav.variants[0].prices[0].original_price > fav.variants[0].prices[0].price">
                      {{fav.variants[0].prices[0].original_price/100 | number }} {{currencyCode}}
                    </span>
              </div><!--End Product-price-->
              <div class="product-status">
                <span *ngIf="
                fav.variants[0].tracked === false ||
                fav.variants[0].stock > 0" class="text-success">In Stock</span>
                <span *ngIf="
                fav.variants[0].tracked &&
                fav.variants[0].stock === 0" class="text-danger">{{language['outOfStock']}}</span>
              </div><!-- End product-status -->
              <div class="product-actions">
                <button (click)="openQuickView(fav)" class="custom-btn reversed d-none">
                  <span class=""> {{language['quickView'] | uppercase}}</span>
                </button>
                <button *ngIf="wishlist.hidePrice" [routerLink]="['/', language['LANGUAGE_CODE'], 'collection', 'product', fav.code]"
                        class="d-inline custom-btn reversed">
                  {{language['addToCart'] | uppercase}}
                  <i class="fa fa-shopping-cart"></i>
                </button>
              </div><!--End Product-action-->
              <div class="product-remove">
                <button class="btn-remove" (click)="removeFromWishlist(fav.code)">
                  <!--<i class="fa fa-times"></i>-->
                  <span  aria-hidden="true">Remove</span>
                </button>
              </div><!--End product-remove-->
            </div><!--End Product-content-->
          </div><!--End product-wishlist-->
        </div><!--End Container-->
      </div><!--End Wishlist-content-->
      <ng-template  #showmessage>
        <div class="empty-wishlist">
          <div class="container">
            <div class="empty-item">
              <i class="fa fa-heart-o"></i>
              <span class="help-text">{{language['emptyWishList']}}</span>
            </div>
            <div class="text-center">
              <a class="custom-btn reversed" routerLink="/collection">{{language['continueShopping']}}</a>
            </div>
          </div><!--End Container-->
        </div><!--End -empty-wishlist-->
      </ng-template>
    </div><!--End wishListBody-->
  
  </section><!--End section-settings-->
  